// the ultimate url
// export const url = 'http://192.168.0.234:4001';
// export const url = 'http://localhost:4001';
export const url = "https://server.ontheway.com.bd";

// fetching customers
export const FETCH_CUSTOMER_STARTS = "FETCH_CUSTOMER_STARTS";
export const FETCH_CUSTOMER_SUCCESS = "FETCH_CUSTOMER_SUCCESS";
export const FETCH_CUSTOMER_FAIL = "FETCH_CUSTOMER_FAIL";

// fetching gigs
export const FETCH_ALL_GIG_SUCCESS = "FETCH_ALL_GIG_SUCCESS";
export const FETCH_PENDING_GIG_SUCCESS = "FETCH_PENDING_GIG_SUCCESS";
export const FETCH_APPROVED_GIG_SUCCESS = "FETCH_APPROVED_GIG_SUCCESS";
export const FETCH_REJECTED_GIG_SUCCESS = "FETCH_REJECTED_GIG_SUCCESS";

// fetching products
export const FETCH_PRODUCTS_STARTS = "FETCH_PRODUCTS_STARTS";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAIL = "FETCH_PRODUCTS_FAIL";
export const SET_PRODUCT = "SET_PRODUCT";
export const SET_UPDATED_PRODUCT = "SET_UPDATED_PRODUCT";
export const FETCH_APPROVED_PRODUCT = "FETCH_APPROVED_PRODUCT";
export const FETCH_PENDING_PRODUCT = "FETCH_PENDING_PRODUCT";
export const FETCH_REJECTED_PRODUCT = "FETCH_REJECTED_PRODUCT";
export const FETCH_DISABLED_PRODUCT = "FETCH_DISABLED_PRODUCT";
export const FETCH_PRODUCT_UPDATE_REQUEST = "FETCH_PRODUCT_UPDATE_REQUEST";

// fetching orders
export const FETCH_ORDERS_START = "FETCH_ORDERS_START";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAIL = "FETCH_ORDERS_FAIL";
export const SET_ORDER = "SET_ORDER";
export const SET_UPDATED_ORDER = "SET_UPDATED_ORDER";
export const FETCH_APPROVED_ORDER = "FETCH_APPROVED_ORDER";
export const FETCH_PENDING_ORDER = "FETCH_PENDING_ORDER";
export const FETCH_SHIPPED_ORDER = "FETCH_SHIPPED_ORDER";
export const FETCH_REJECTED_ORDER = "FETCH_REJECTED_ORDER";
export const FETCH_DELIVERED_ORDER = "FETCH_DELIVERED_ORDER";

// gig orders
export const GET_RECENT_GIG_ORDERS = "GET_RECENT_GIG_ORDERS";
export const GET_PENDING_GIG_ORDERS = "GET_PENDING_GIG_ORDERS";
export const GET_APPROVED_GIG_ORDERS = "GET_APPROVED_GIG_ORDERS";
export const GET_DELIVERED_GIG_ORDERS = "GET_DELIVERED_GIG_ORDERS";
export const GET_REJECTED_GIG_ORDERS = "GET_REJECTED_GIG_ORDERS";
export const GET_REPORTED_GIG_ORDERS = "GET_REPORTED_GIG_ORDERS";

// single gig order
export const GET_SINGLE_PENDING_GIG_ORDERS = "GET_SINGLE_PENDING_GIG_ORDERS";
export const GET_SINGLE_APPROVED_GIG_ORDERS = "GET_SINGLE_APPROVED_GIG_ORDERS";
export const GET_SINGLE_DELIVERED_GIG_ORDERS =
  "GET_SINGLE_DELIVERED_GIG_ORDERS";
export const GET_SINGLE_REJECTED_GIG_ORDERS = "GET_SINGLE_REJECTED_GIG_ORDERS";
export const GET_SINGLE_REPORTED_GIG_ORDERS = "GET_SINGLE_REPORTED_GIG_ORDERS";

// fetcheing queens offer

export const GET_ALL_QUEENSOFFER = "GET_ALL_QUEENSOFFER";
export const GET_ACTIVE_QUEENSOFFER = "GET_PENDING_QUEENSOFFER";
export const GET_EXPIRED_QUEENSOFFER = "GET_EXPIRED_QUEENSOFFER";
export const GET_DELETED_QUEENSOFFER = "GET_DELETED_QUEENSOFFER";

// fetching queens
export const FETCH_QUEENS_SUCCESS = "FETCH_QUEENS_SUCCESS";
export const FETCH_PENDING_QUEEN = "FETCH_PENDING_QUEEN";
export const FETCH_APPROVED_QUEEN = "FETCH_APPROVED_QUEEN";
export const FETCH_REJECTED_QUEEN = "FETCH_REJECTED_QUEEN";

export const FETCH_QUEENS_FAIL = "FETCH_QUEENS_FAIL";
export const SET_UPDATED_QUEEN = "SET_UPDATED_QUEEN";
export const SET_QUEEN = "SET_QUEEN";
export const FETCH_ALL_NEW_QUEENS = "FETCH_ALL_NEW_QUEENS";

// fetching offers
export const FETCH_OFFERS_START = "FETCH_OFFERS_START";
export const FETCH_OFFERS_SUCCESS = "FETCH_OFFERS_SUCCESS";
export const FETCH_OFFERS_FAIL = "FETCH_OFFERS_FAIL";
export const SET_OFFER = "SET_OFFER";
export const SET_UPDATED_OFFER = "SET_UPDATED_OFFER";

// fetching trainee
export const FETCH_TRAINEE_SUCCESS = "FETCH_TRAINEE_SUCCESS";
export const FETCH_PENDING_TRAINEE = "FETCH_PENDING_TRAINEE";
export const FETCH_APPROVED_TRAINEE = "FETCH_APPROVED_TRAINEE";
export const FETCH_REJECTED_TRAINEE = "FETCH_REJECTED_TRAINEE";

export const SET_UPDATED_TRAINEE = "SET_UPDATED_TRAINEE";

// fetching admins
export const FETCH_ADMIN_START = "FETCH_ADMIN_START";
export const FETCH_ADMIN_SUCCESS = "FETCH_ADMIN_SUCCESS";
export const FETCH_ADMIN_FAIL = "FETCH_ADMIN_FAIL";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";

// dashboard constants
export const FETCH_DASHBORAD = "FETCH_DASHBORAD";
export const CANT_FETCH_DASHBORAD = "CANT_FETCH_DASHBORAD";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const RESET_FORM_DATA = "RESET_FORM_DATA";

// socket constants
export const NEW_OFFER = "NEW_OFFER";
export const NEW_ORDER = "NEW_ORDER";
export const NEW_QUEEN = "NEW_QUEEN";
export const NEW_TRAINEE = "NEW_TRAINEE";
export const NEW_PRODUCT = "NEW_PRODUCT";
export const NEW_UPDATE_PRODUCT = "NEW_UPDATE_PRODUCT";

// date reducer constants
export const TODAY_DATE = "TODAY_DATE";
export const FILTERED_DATE = "FILTERED_DATE";
export const FILTERED_DATE2 = "FILTERED_DATE2";
export const FILTERED_DATE3 = "FILTERED_DATE3";
export const BEFORE_DATE = "BEFORE_DATE";
export const FILTERED_LENGTH = "FILTERED_LENGTH";

// inbox reducer constants
export const SET_ALL_MESSAGES = "SET_ALL_MESSAGES";
export const SET_NEW_MESSAGE = "SET_NEW_MESSAGE";
export const SET_UNREAD_COUNT = "SET_UNREAD_COUNT";

// queen type constants
export const FETCH_APPROVED_SUCCESS = "FETCH_APPROVED_SUCCESS";
export const FETCH_PENDING_SUCCESS = "FETCH_PENDING_SUCCESS";
export const FETCH_REJECTEED_SUCCESS = "FETCH_REJECTEED_SUCCESS";

// notification reducer
export const SET_ALL_NOTIFICATIONS = "SET_ALL_NOTIFICATIONS";
export const SET_NEW_NOTIFICATION = "SET_NEW_NOTIFICATION";
export const SET_UNREAD_NOTIFICATION_COUNT = "SET_UNREAD_NOTIFICATION_COUNT";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";

// hamburger
export const HAMBURGER_SHOW = "HAMBURGER_SHOW";

// limit
export const limit = 50;

// seller constants
export const FETCH_APPROVED_SELLER = "FETCH_APPROVED_SELLER";
export const FETCH_PENDING_SELLER = "FETCH_PENDING_SELLER";
export const FETCH_SELLERS_SUCCESS = "FETCH_SELLERS_SUCCESS";
export const FETCH_REJECTED_SELLER = "FETCH_REJECTED_SELLER";
export const NEW_SELLER = "NEW_SELLER";
export const SET_UPDATED_SELLER = "SET_UPDATED_SELLER";

// fetching questions
export const FETCH_QUESTION_NOT_ANSWER_SUCCESS =
  "FETCH_QUESTION_NOT_ANSWER_SUCCESS";
export const FETCH_QUESTION_ANSWER_SUCCESS = "FETCH_QUESTION_ANSWER_SUCCESS";
export const FETCH_QUESTION_DELETED_SUCCESS = "FETCH_QUESTION_DELETED_SUCCESS";

// fetching blogs
export const FETCH_BLOGS = "FETCH_BLOGS";
// social users constants
export const FETCH_APPROVED_SOCIAL_USERS = "FETCH_APPROVED_SOCIAL_USERS";
export const FETCH_PENDING_SOCIAL_USERS = "FETCH_PENDING_SOCIAL_USERS";
export const FETCH_SOCIAL_USERS_SUCCESS = "FETCH_SOCIAL_USERS_SUCCESS";
export const FETCH_REJECTED_SOCIAL_USERS = "FETCH_REJECTED_SOCIAL_USERS";
export const NEW_SOCIAL_USERS = "NEW_SOCIAL_USERS";
export const SET_UPDATED_SOCIAL_USERS = "SET_UPDATED_SOCIAL_USERS";
